<template>
  <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
    <form @submit.prevent="reset()">
      <div class="mb-4">
        <!-- <label class="block text-gray-700 text-sm font-bold mb-2" for="old_password">
          Old password
        </label> -->
        <Input v-model="form.old_password" :placeholder="$t('login.oldPassword')" id="old_password" type="password" required />
        <!-- <input v-model="form.old_password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="old_password" type="password" placeholder="•••••••••••"> -->
      </div>
      <div class="mb-6">
        <!-- <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
          Password
        </label> -->
        <Input v-model="form.password" :placeholder="$t('login.newPassword')" type="password" id="password" required />
        <!-- <input v-model="form.password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="•••••••••••"> -->
      </div>
       <div class="mb-6">
        <!-- <label class="block text-gray-700 text-sm font-bold mb-2" for="password2">
          Password again
        </label> -->
        <Input v-model="form.password2" :placeholder="$t('login.confirmPassword')" type="password" id="password2" required />
        <!-- <input v-model="form.password2" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password2" type="password" placeholder="•••••••••••"> -->
      </div>
      <div v-show="error" class="mb-6">
        <label class="block text-gray-700 text-sm font-bold mb-2" style="background-color: #ff00008a; font-size: 12px;">
          {{error}}
        </label>
      </div>
      <div class="flex items-center justify-between">
        <Button buttonType="primary" :text="$t('login.reset')" :disabled="buttom_disabled" type="submit"/>

        <!-- <button :disabled="buttom_disabled" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
          Reset 
        </button> -->
      </div>
    </form>
    <MobileNavigation />
  </div>
</template>
<script>

import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';
import MobileNavigation from "@/components/mobile/Navigation.vue";

export default {
  components: {
        Input,
        Button,
        MobileNavigation,
    },
  data(){
    return {
      baseURL: process.env.VUE_APP_AXIOS_URL,
      form: {
        old_password: '',
        password: '',
        password2: '',
        user_id: '',
      },
      error: '',
      buttom_disabled: false,
    }
  },
  mounted() {
    if (this.baseURL.includes('product.')) {
      this.baseURL = this.baseURL.replace('product.', '');
    }
  },
  methods: {
    async reset() {
        const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
        this.form.user_id = authUser.data.user.id;
        this.error = '';
        this.buttom_disabled = true;
        this.axios.put(this.baseURL + `/wp-json/wp/v2/password-reset`, this.form, {}).then(response => {
            const response_data = response.data;
            if (response_data.error) {
                this.error = response_data.error;
                this.buttom_disabled = false;
            } else {
                alert("Your password was change!");
                this.$router.go(-1);
            }
        }).catch( (error) => {
            console.log(error);
            this.error = 'Server problem!';
            this.buttom_disabled = false;
        });
        
    },
    langchanged_(value) {
        this.lang = value;
    },
  }
}
</script>